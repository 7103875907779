<template>
    <Row>
      <i-col span="24">
        <Divider dashed><span class="divider-text">开票汇总</span></Divider>
        <Row class="table-title p-t-2">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">费用类型</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">开票金额(含税)</i-col>
        </Row>

        <Row v-for="(item,index) in summary.feeTypeInvoicedList" :key="index" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{item.feeTypeName}}</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5 money">{{formatMoney(item.invoicedAmount)}}</i-col>
        </Row>
        <Row  class="table-row-1">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">小计</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5 money">{{formatMoney(summary.totalInvoicedAmount)}}</i-col>
        </Row>
        <Row  class="table-row-1">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">未开票金额(含税)</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5 money">{{formatMoney(summary.totalUnInvoiceAmount)}}</i-col>
        </Row>

      </i-col>
    </Row>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getinvoicesummary } from '@/api/scp/invoice'
export default {
  props: {
    id: Number
  },
  data () {
    return {
      summary: {
        feeTypeInvoicedList: []
      }
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    reload () {
      this.initPageData()
    },
    formatMoney (number) {
      return toMoney(number)
    },
    initPageData () {
      getinvoicesummary({ relateId: this.id }).then(res => {
        this.summary = res
      })
    }
  },
  watch: {
  }
}
</script>
